import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const OrdenesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">

      <CommunicationImport
        title="Actualiza órdenes"
        config={getCommunicationConfig('actualiza-ordenes')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-ordenes' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('actualiza-ordenes')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'zzvv', permission: 'export-ordenes' }}
      />
    </Page>
  );
};

export default OrdenesCommunicationsPage;
