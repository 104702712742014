import { ZonasVerdes } from '../../../../API';
import CommunicationExport from '../../../../components/CommunicationExport';
import CommunicationImport from '../../../../components/CommunicationImport';
import Page from '../../../../components/Page';
import { getCommunicationConfig } from '../../shared/utils';
import { campos } from './campos';

const ActuacionesCommunicationsPage = (): JSX.Element => {
  return (
    <Page className="communications" titleType="title">
      <CommunicationImport
        title="Cargas"
        config={getCommunicationConfig('actuaciones')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-actuaciones' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas - Activos actuación"
        config={getCommunicationConfig('actuaciones-activos')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-actuaciones' }}
      />

      <hr />

      <CommunicationImport
        title="Cargas - Incidencias actuación"
        config={getCommunicationConfig('actuaciones-incidencias')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-actuaciones' }}
      />

      <hr />

      <CommunicationImport
        title="Actualiza estado actuación"
        config={getCommunicationConfig('actualiza-estado-actuacion')}
        importApi={ZonasVerdes.import}
        requirePermission={{ section: 'zzvv', permission: 'import-actuaciones' }}
      />

      <hr />

      <CommunicationExport
        config={getCommunicationConfig('actuaciones')}
        exportApi={ZonasVerdes.export}
        title="Consultas"
        campos={campos}
        requirePermission={{ section: 'zzvv', permission: 'export-actuaciones' }}
      />
    </Page>
  );
};

export default ActuacionesCommunicationsPage;
